<script>
import { mapActions, mapMutations } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { SweetModal } from 'sweet-modal-vue'
import VuePdfApp from 'vue-pdf-app'
import { id } from 'vuejs-datepicker/dist/locale'
import mixinHelper from '@/mixins/helper'
import 'vue-pdf-app/dist/icons/main.css'

export default {
   name: 'LaporanOss',
   components: {
      BCardCode,
      SweetModal,
      VuePdfApp,
   },
   mixins: [mixinHelper],
   data() {
      return {
         pdf: '',
         loading: true,
         loadingPdf: false,
         filterDateValue: new Date(),
         filterDateLang: id,
         form: {
            tanggal: null,
            triwulan: {
               triwulan: null,
               tahun: null,
               jenis: 'Baru',
            },
         },
         options: {
            triwulan: [
               { value: null, text: '-' },
               { value: 'I', text: 'I' },
               { value: 'II', text: 'II' },
               { value: 'III', text: 'III' },
               { value: 'IV', text: 'IV' },
            ],
            jenis: [
               { value: 'Baru', text: 'Baru' },
               { value: 'Baru & Perubahan', text: 'Baru & Perubahan' },
            ],
         },
      }
   },
   watch: {
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      'form.triwulan.tahun': function _(newVal) {
         this.form.triwulan.tahun = this.convertDate2Year(newVal)
      },
   },
   methods: {
      ...mapActions({
         cetakLaporan: 'OssNib/cetakLaporan',
         cetakLaporanTriwulan: 'OssNib/cetakLaporanTriwulan',
      }),
      ...mapMutations({
         setIdProyek: 'OssBidangUsaha/SET_ID_PROYEK',
      }),
      myFilter() {
         // const d = new Date(this.filterDateValue)
         // const filterDateValue = this.$moment(d).format('YYYY-MM-DD')

         // this.form.tanggal = this.filterDateValue
         console.log('tanggal', this.form.tanggal)
      },
      onCetakLaporan() {
         this.loadingPdf = true
         this.cetakLaporan(this.form.tanggal).then(
            res => {
               const blob = new Blob([res.data])
               this.pdf = URL.createObjectURL(blob)
               // URL.revokeObjectURL(objectURL)
               this.$refs.modalPdf.open()
               this.loadingPdf = false
            },
            () => {
               this.loadingPdf = false
            },
         )
      },
      onCetakLaporanTriwulan() {
         this.loadingPdf = true
         this.cetakLaporanTriwulan(this.form.triwulan).then(
            res => {
               const blob = new Blob([res.data])
               this.pdf = URL.createObjectURL(blob)
               // URL.revokeObjectURL(objectURL)
               this.$refs.modalPdf.open()
               this.loadingPdf = false
            },
            () => {
               this.loadingPdf = false
            },
         )
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Laporan Harian">
         <b-row>
            <b-col cols="12" md="6">
               <div class="d-flex align-items-center">
                  <div class="input-group" role="group">
                     <datepicker
                        v-model="form.tanggal"
                        input-class="datepicker-here form-control "
                        minimum-view="date"
                        maximum-view="year"
                        format="dd MMMM yyyy"
                        :language="filterDateLang"
                        @input="myFilter"
                     />
                     <div class="input-group-append">
                        <button type="button" class="btn btn-outline-primary" @click="onCetakLaporan()">
                           Cetak
                        </button>
                     </div>
                  </div>
               </div>
            </b-col>
         </b-row>
      </b-card-code>

      <b-card-code title="Laporan Triwulan">
         <b-row>
            <b-col cols="12" md="6">
               <div class="d-flex align-items-center">
                  <b-form-group label="Tahun" label-for="tahun">
                     <datepicker
                        v-model="form.triwulan.tahun"
                        :minimum-view="'year'"
                        :maximum-view="'year'"
                        format="yyyy"
                        input-class="datepicker-here form-control "
                     />
                  </b-form-group>
                  <b-form-group label="Jenis Data" label-for="Jenis Data">
                     <b-form-select v-model="form.triwulan.jenis" :options="options.jenis" />
                  </b-form-group>
                  <b-form-group label="Triwulan" label-for="Triwulan">
                     <div class="input-group" role="group">
                        <b-form-select v-model="form.triwulan.triwulan" :options="options.triwulan" />
                        <div class="input-group-append">
                           <button type="button" class="btn btn-outline-primary" @click="onCetakLaporanTriwulan()">
                              Cetak
                           </button>
                        </div>
                     </div>
                  </b-form-group>

               </div>
            </b-col>
         </b-row>
      </b-card-code>

      <sweet-modal ref="modalPdf" modal-theme="light" overlay-theme="dark" width="80%">
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
.borderless>tr>td {
   border: none;
   padding: 0em;
}

table>tbody>tr>td>span>hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
