export default {
   computed: {
      convertNumberOnly() {
         return val => {
            if (val) {
               // eslint-disable-next-line radix
               return parseInt(val.match(/\d+/g).join([]))
            }

            return null
         }
      },
      convertDate2Year() {
         return val => {
            if (val) {
               const d = new Date(val)
               return this.$moment(d).format('YYYY-MM-DD').slice(0, 4)
            }

            return null
         }
      },
   },
}
